import { graphql } from 'gatsby'
import React, { useEffect } from 'react'

import { DESCRIPTION } from '../constants/metaInfo'
import HomeGrid from '../components/home/homeGrid'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Helmet from 'react-helmet'
import Banner from '../components/banner'
import { breakpoints } from '../constants/app'
import useViewportSize from '../utils/useViewportSize'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'الصفحة الرئيسية | الشرق'
  const { width } = useViewportSize()

  let url = `${process.env.GATSBY_BASE_URL}`
  url = url.substr(url.length - 1)

  const canonicalUrl = `${process.env.GATSBY_BASE_URL}`

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <>
      <div>
        <div style={{ padding: '0 10px' }}>
          {width && width > breakpoints.m && <Banner page="homepage" />}
        </div>
      </div>
      <Layout location={location} title={siteTitle} lang={'ar'} allowPopUp>
        <h1 className="customH1">{'الشرق للأخبار'}</h1>
        <SEO
          title={seoTitle}
          lang="ar"
          description={DESCRIPTION}
          canonicalUrl={canonicalUrl}
          shouldIncludeSocialMeta={false}
        />
        <Helmet>
          <title>
            الشرق للأخبار - Asharq News | آخر الأخبار العربية والعالمية
          </title>
          <meta
            name="description"
            content="أهم وأبرز الأخبار العاجلة عربياً وعالمياً ومستجدات الشرق الأوسط والعالم، حيث نوافيك بأهم التفاصيل والتغطيات من كل البلدان والمزيد من الشرق للأخبار Asharq News."
          />
          <meta
            name="keywords"
            content="اخر الاخبار اليوم, اخبار الشرق الاوسط, اخبار العالم العربي, اخبار الخليج, اخبار العالم,اخر الاخبار, اخبار عاجلة, اهم الاخبار, اخر الاخبار العاجلة, اخبار السعودية, اخبار مصر, اخبار الامارات, اخبار سوريا, اخبار لبنان, اخبار العراق, اخبار المغرب, اخبار الجزائر, اخبار السودان، اخبار الاردن، اخبار الكويت ، اخبار عمان ، اخبار دبي ، اخبار اليوم ، اخبار عاجلة"
          />
          <meta property="fb:app_id" content="236955207547425" />
          <meta property="og:url" content={url} />
          <script type="application/ld+json">
            {`
            {

              "@context": "https://schema.org",
              
              "@type": "NewsMediaOrganization",
              
              "url": "https://asharq.com",
              
              "name": "الشرق للأخبار",
              
              "alternateName": "Asharq News",
              
              "logo": "${process.env.GATSBY_BASE_URL}asharq-logo.svg",
              
              "description": "الشرق هي مؤسسة إعلامية مستقلة متكاملة الأذرع والمنصات تتبع شركة الشرق للخدمات الإخبارية المحدودة. وشركة الشرق للخدمات الإخبارية المحدودة، مسجلة في مركز دبي المالي العالمي، ومملوكة لـ المجموعة السعودية للأبحاث والتسويق وهي شركة مساهمة عامة",
              
              "sameAs": [
              
              "https://www.facebook.com/asharqnews",
              
              "https://twitter.com/Asharqnews",
              
              "https://www.instagram.com/asharqnews/",
              
              "https://www.youtube.com/c/asharqnews",
              
              "https://www.linkedin.com/company/asharqnews/"]
            }
          `}
          </script>
          <link
            rel="alternate"
            href="android-app://com.alsharq.app.alsharq_app/http/asharq.com"
          />
          <link rel="alternate" href="ios-app://id1539149426/http/asharq/" />
        </Helmet>

        <HomeGrid />
      </Layout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
